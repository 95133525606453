define("discourse/plugins/discourse-solved-reminders-plugin/discourse/components/solutions-notification-preferences", ["exports", "@ember/component", "ember-this-fallback/deprecations-helper", "@ember/template-factory"], function (_exports, _component, _deprecationsHelper, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  const __COLOCATED_TEMPLATE__ = (0, _templateFactory.createTemplateFactory)(
  /*
    <div class="control-group follow-notifications">
    <label class="control-label">{{i18n
        "discourse_solved_reminders.notifications.label"
      }}</label>
  
    <div class="controls">
      <label class="checkbox-label">
        {{input
          type="checkbox"
          checked=user.custom_fields.dont_send_accepted_solution_notifications
        }}
        {{i18n
          "discourse_solved_reminders.notifications.dont_send_accepted_solution_notifications"
        }}
      </label>
    </div>
  </div>
  */
  {
    "id": "6SBMDxUa",
    "block": "[[[10,0],[14,0,\"control-group follow-notifications\"],[12],[1,\"\\n  \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,[28,[35,0],[\"discourse_solved_reminders.notifications.label\"],null]],[13],[1,\"\\n\\n  \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n    \"],[10,\"label\"],[14,0,\"checkbox-label\"],[12],[1,\"\\n      \"],[1,[28,[35,1],null,[[\"type\",\"checked\"],[\"checkbox\",[30,0,[\"user\",\"custom_fields\",\"dont_send_accepted_solution_notifications\"]]]]]],[1,\"\\n      \"],[1,[28,[35,0],[\"discourse_solved_reminders.notifications.dont_send_accepted_solution_notifications\"],null]],[1,\"\\n    \"],[13],[1,\"\\n  \"],[13],[1,\"\\n\"],[13],[1,[28,[32,0],[\"[[\\\"The `user` property path was used in the `discourse/plugins/discourse-solved-reminders-plugin/discourse/components/solutions-notification-preferences.hbs` template without using `this`. This fallback behavior has been deprecated, all properties must be looked up on `this` when used in the template: {{this.user}}\\\",false,{\\\"id\\\":\\\"ember-this-fallback.this-property-fallback\\\",\\\"until\\\":\\\"n/a\\\",\\\"for\\\":\\\"ember-this-fallback\\\",\\\"url\\\":\\\"https://deprecations.emberjs.com/v3.x#toc_this-property-fallback\\\",\\\"since\\\":{\\\"available\\\":\\\"0.2.0\\\"}}]]\"],null]]],[],false,[\"i18n\",\"input\"]]",
    "moduleName": "discourse/plugins/discourse-solved-reminders-plugin/discourse/components/solutions-notification-preferences.hbs",
    "scope": () => [_deprecationsHelper.default],
    "isStrictMode": false
  });
  var _default = _exports.default = (0, _component.setComponentTemplate)(__COLOCATED_TEMPLATE__, _component.default.extend({}));
});